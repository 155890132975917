import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import SEO from "../component/SEO";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="About Us - Calcuvate"
        description="Discover Calcuvate for the complex calculations. From finance and science to mathematics and fitness, we fix all the problems for your needs."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/about-us"
      />
      <Layout>
        <div className="container">
          <section className="other-commn-page content-cover-box">
            <div>
              <h1 className="primary-font">about US</h1>
            </div>
            <div className="">
              {/* <div>
              <b>Effective Date</b>: [Insert Date]
            </div> */}
              <p>
                Calcuvate&apos;, aims to provide a wide range of free online
                calculators for the diverse needs of the public. Our team of IT
                experts moves forward with an innovative approach to make your
                daily life calculations with ease. We&apos;ve decided to support
                the users by delivering various calculators across categories like
                fitness & health, finance, mathematics, conversions, etc.
              </p>
              <p>
                &apos;Calcuvate&apos; is a calculation tool designed by our
                skilled IT professionals dedicated to simplifying calculations for
                users. We understand the varying needs of the community.
                Therefore, we&apos;ve developed a comprehensive pack of free
                calculators on the digital platform.
              </p>
              <p>
                Our user-friendly and innovative design is a commitment that
                ensures anyone can access these tools at any time. You can use
                these tools for educational purposes, tracking health & fitness,
                managing finances, and many other purposes. It eases the effort
                and saves the crucial time of people in the community.
              </p>
              <p>
                In addition, we empower individuals with an online accessible tool
                to calculate everyday tasks. We strive to become your go-to
                resource for difficult calculations. Moreover, we have created the
                best options according to the different demands making them
                accessible for your convenience.
              </p>
              <p>
                We continue to make improvements that ensure that our services get
                better over time. We&apos;re excited to introduce new features
                that provide a tremendous amount of satisfaction in the overall
                experience.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
