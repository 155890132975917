import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import Icons from "../Assets/Icons";

export default function BMICalculator() {
  return (
    <Layout>
      <div className="container">
        <section className="content-cover-box">
          <div className="calculate-percentage">
            {/* Percentage Calculator  */}

            <div className="">
              <h2 className="primary-font mt-0 mb-1">
                BMI(Body Mass Index) Calculator
              </h2>
              <div className="calculate-percentage-label">
                Adjust the values and press the calculate button to see the
                results
              </div>
              <div className="calculate-card">
                <div className="bmi-calculate">
                  <div className="mbi-calculate-left">
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Age</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="20" />
                        <div className="bmi-age-count">
                          <p>Ages: 2-120</p>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Gender</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Male</span>
                        </div>
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Female</span>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Hight</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="172" />
                        <div className="">
                          <select>
                            <option>Cm</option>
                            <option>Cm</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Weight </p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="62" />
                        <div className="">
                          <select>
                            <option>Kg</option>
                            <option>Kg</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btns">
                      <button className="btn-theme">Calculate</button>
                      <button className="btn-theme-outline">Clear</button>
                    </div>
                  </div>
                  <div className="mbi-calculate-right"></div>
                </div>
              </div>
            </div>
            <div className="container">
              <ul className="bmi-range">
                <li>Healthy BMI range: 18.5 kg/m2 - 24.9 kg/m2</li>
                <li>Healthy weight for the height: 57.3 kg - 77.4 kg</li>
                <li>BMI Prime: 0.86</li>
                <li>Ponderal Index: 12.3 kg/m3</li>
              </ul>
            </div>

            {/* <div className="container"> */}
            <div className="share-link-calculator">
              <div className="">
                <input
                  type="text"
                  value="https:// .flaticon.com /free-icon/sh are_182895"
                />
                <p>Copy and share this link</p>
              </div>
              <button className="share-link-btn">
                Share {Icons.ShareBtnIcon}
              </button>
            </div>
            {/* </div> */}

            <p>
              One of the most important tools for our wellness journey is the
              Body Mass Index(BMI) calculator. It helps an individual understand
              ways to maintain a healthy body weight based on their height to
              stay healthy. It serves as a key to managing body health in
              today’s health-conscious world.
            </p>
            <p>
              A quick way to categorize an individual as underweight, normal
              weight, overweight, or obese. So, how do you know whether you fall
              in the healthy weight range or not?
            </p>
            <h2 className="primary-font">How to Calculate BMI?</h2>
            <p>
              You can calculate it using two different formulas, either the
              metric or the imperial system.
            </p>
            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Metric System </b>
                  <br />
                  Here, it is calculated by dividing weight in kilograms with
                  height in square meters. Measure your weight and height,
                  square your height, and then divide your weight by this
                  squared value to find your BMI.
                </li>
                <li>
                  <b>Imperial System</b>
                  <br />
                  Here, BMI is found by dividing your weight in pounds by your
                  height in inches squared, then multiplying by 703. To
                  calculate your body mass index, first measure your weight and
                  height to square your height. In addition, divide your weight
                  by this squared height, and multiply the result by 703.
                </li>
              </ul>
            </div>

            <h2 className="primary-font">What is Normal BMI?</h2>
            <p>
              A normal body mass index falls between 18.5 and 25. BMI falling
              between 25 and 30, considers you overweight, while over 30 will
              classify you as obese. Also, a BMI below 18.5 indicates you are
              underweight.
            </p>

            <h2 className="primary-font">What are the various BMI ranges?</h2>
            <p>
              For adults over the age of 20 years, we group these values into
              the following weight status categories. Let’s look at a few
              categories ahead:
            </p>

            <div class="table-container">
              <table>
                <tr>
                  <th>BMI</th>
                  <th>Weight Status</th>
                </tr>
                <tr>
                  <td>Below 18.5</td>
                  <td>Underweight</td>
                </tr>
                <tr>
                  <td>18.5-24.9</td>
                  <td>Normal weight</td>
                </tr>
                <tr>
                  <td>25-29.9</td>
                  <td>Overweight</td>
                </tr>
                <tr>
                  <td>30-35</td>
                  <td>Obese</td>
                </tr>
                <tr>
                  <td>Over 35</td>
                  <td>Morbid Obesity</td>
                </tr>
              </table>
            </div>

            <h2 className="primary-font">Purpose of BMI</h2>
            <p>
              BMI provides a straightforward way to categorize individuals based
              on their weight status. It can be useful in identifying potential
              health risks associated with being underweight, overweight, or
              obese. It is especially valuable in public health settings for
              tracking weight trends in populations. Also, It assesses the
              effectiveness of weight management programs.
            </p>

            <h2 className="primary-font">Track BMI with ‘Calcuvate’ </h2>
            <p>
              ‘Calcuvate’ is a free online tool that performs a lot of
              mathematical calculations based on various functions including
              Body Mass Index (BMI). Our objective is to save your precious time
              used in tracking health. Our team of experts delivers quick
              responses and convenient access with guaranteed accuracy.
              Additionally, it is the method to gauge whether your weight is in
              healthy proportion to your height. This helps to know your overall
              health status. Our Free BMI Calculator includes an in-depth
              explanation of body fat as well as muscle mass. It helps users to
              understand what the results tell about their health. Thus, you can
              use it as an educational resource too.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
}
