import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PercentageCalculator from "./pages/PercentageCalculator";
import BMICalculator from "./pages/BMICalculator";
import CalorieCalculator from "./pages/CalorieCalculator";
import BMRCalculator from "./pages/BMRCalculator";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsUse from "./pages/TermsUse";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import TheImportanceOfOnlineScientificCalculator from "./pages/TheImportanceOfOnlineScientificCalculator";
import MortgageCalculatorTrackingMonthlyPayments from "./pages/MortgageCalculatorTrackingMonthlyPayments";
import HowMathCalculatorsSimplifyStudentLife from "./pages/HowMathCalculatorsSimplifyStudentLife";
import BMICalculatorAPathToMaintainHealth from "./pages/BMICalculatorAPathToMaintainHealth";
import TheRoleOfFreeOnlineCalculatorsInHealthFitness from "./pages/TheRoleOfFreeOnlineCalculatorsInHealthFitness";
import MasterRepaymentStrategiesWithAnOnlineLoanCalculator from "./pages/MasterRepaymentStrategiesWithAnOnlineLoanCalculator";
import ManageFinancesWithOnlinePayrollCalculator from "./pages/ManageFinancesWithOnlinePayrollCalculator";
import TipsForUsingALoanCalculator from "./pages/TipsForUsingALoanCalculator";
import UseSalaryCalculatorCalculateYourWorth from "./pages/UseSalaryCalculatorCalculateYourWorth";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="percentage-calculator"
            element={<PercentageCalculator />}
          ></Route>
          <Route path="bmi-calculator" element={<BMICalculator />}></Route>
          <Route
            path="calorie-calculator"
            element={<CalorieCalculator />}
          ></Route>
          <Route path="bmr-calculator" element={<BMRCalculator />}></Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="terms-of-use" element={<TermsUse />}></Route>
          <Route path="about-us" element={<AboutUs />}></Route>
          <Route path="contact-us" element={<ContactUs />}></Route>
          <Route
            path="the-importance-of-online-scientific-calculator"
            element={<TheImportanceOfOnlineScientificCalculator />}
          ></Route>
          <Route
            path="mortgage-calculator-tracking-monthly-payments"
            element={<MortgageCalculatorTrackingMonthlyPayments />}
          ></Route>
          <Route
            path="how-online-math-calculators-simplify-students-life"
            element={<HowMathCalculatorsSimplifyStudentLife />}
          ></Route>
          <Route
            path="bmi-calculator-a-path-to-maintain-health"
            element={<BMICalculatorAPathToMaintainHealth />}
          ></Route>
          <Route
            path="the-role-of-free-online-calculators-in-health-fitness"
            element={<TheRoleOfFreeOnlineCalculatorsInHealthFitness />}
          ></Route>
          <Route
            path="master-repayment-strategies-with-an-online-loan-calculator"
            element={<MasterRepaymentStrategiesWithAnOnlineLoanCalculator />}
          ></Route>
          <Route
            path="manage-finances-with-online-payroll-calculator"
            element={<ManageFinancesWithOnlinePayrollCalculator />}
          ></Route>
          <Route
            path="tips-for-using-a-loan-calculator"
            element={<TipsForUsingALoanCalculator />}
          ></Route>
          <Route
            path="use-salary-calculator-calculate-your-worth"
            element={<UseSalaryCalculatorCalculateYourWorth />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
