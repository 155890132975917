import React from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import SEO from "../component/SEO";

export default function TermsUse() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="Terms of Use - Calcuvate"
        description="Find out why should we obey the Terms of Use Agreement, while using 'Calcuvate'. Check out the guidelines under which one should use our website."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/terms-of-use"
      />
      <Layout>
        <div className="container">
          <section className="other-commn-page content-cover-box">
            <div>
              <h1 className="primary-font">Terms of Use</h1>
            </div>
            <div className="">
              <h2 className="primary-font">Acceptance Of Terms</h2>
              <p>
                &apos;Calcuvate&apos; (refers to hereafter the website) is for use
                by consumers and businesses. By using this website, you must obey
                the Terms of Use agreement. If you do not agree with any part of
                the Terms and conditions or the website guidelines. Therefore, you
                should stop using the website right away.
              </p>

              <h2 className="primary-font">Changes to the Terms of Use</h2>
              <p>
                We hold the right to change, update, or modify the terms of use at
                any time at our discretion. These updates become effective right
                after posting. Find the latest version of the terms and conditions
                in the footer of our website. Keeping a check on the Terms of Use
                regularly to stay informed, is the consumer's responsibility.
              </p>

              <h2 className="primary-font">Privacy</h2>
              <p>
                Check out our Privacy Policy to know how we gather, share, and
                utilize your personal information. By using the website, you agree
                to both the Privacy Policy and the Terms of Use.
              </p>
              <h2 className="primary-font">Applicable Laws</h2>
              <p>
                These Terms of Use are subject to the laws applicable to our
                business operations. Users are responsible for ensuring they
                comply with any relevant laws in their jurisdiction.
              </p>
              <h2 className="primary-font">Content and Use</h2>
              <p>
                &apos;Calcuvate&apos; aims to provide accurate and reliable free
                online calculators. Moreover, we guarantee the accuracy of our
                results. Users should verify calculations and use them at their
                own risk.
              </p>
              <h2 className="primary-font">User Responsibilities</h2>
              <p>
                {" "}
                Users agree not to misuse the website or its services. This
                includes posting harmful content, attempting to access
                unauthorized areas, or interfering with the website's operation.
                These actions are strictly prohibited.
              </p>
              <h2 className="primary-font">Contact Information</h2>
              <p>
                For any further concerns about these Terms of Use, please reach
                out to us through our website.
              </p>

              <p>
                Thank you for choosing &apos;Calcuvate&apos; for your daily life
                calculations! Here, we hope to deliver the best results to our
                users by easing their efforts.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
