import Icons from "../Assets/Icons";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <div className="container position-relative">
        <div className="common-header">
          <div className="main-logo">
            <Link to="https://www.calcuvate.com/" aria-label="Calcuvate Home Page">{Icons.LogoMain}</Link>
          </div>
          <div className="search-nav-bar">
            {/* <input type="search" placeholder="Search here..." /> */}
          </div>
        </div>
      </div>
    </>
  );
}
