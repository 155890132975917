import React from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import SEO from "../component/SEO";

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="Privacy Policy - Calcuvate"
        description="Get to know all our policies used to safeguard your personal information. This policy explains how 'Calcuvate' gathers, uses, and secures your data."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/privacy-policy"
      />
      <Layout>
        <div className="container">
          <section className="other-commn-page content-cover-box">
            <div>
              <h1 className="primary-font">Privacy Policy</h1>
            </div>
            <div className="">
              {/* <div>
              <b>Effective Date</b>: [Insert Date]
            </div> */}
              <p>
                Welcome to Calcuvate! We value your privacy. We are focused on
                safeguarding your personal information. This Privacy Policy
                explains how we gather, use, and secure your data when you use our
                calculators.
              </p>
              <h2 className="primary-font">1. Information We Collect</h2>

              <ul>
                <li>
                  Personal Information: We do not require any personal information
                  to use our calculators. You can access our tools without
                  providing your name, email, or any other personal details.
                </li>
                <li>
                  Usage Data: We may collect information about how you use our
                  website, such as your IP address, browser type, and the pages
                  you visit. This helps us improve our services.
                </li>
              </ul>
              <h2 className="primary-font">2. How We Use the Information</h2>

              <ul>
                <li>
                  We use usage data to understand how our calculators are used and
                  to enhance user experience.
                </li>
                <li>
                  We do not disclose or sell your personal information to third
                  parties.
                </li>
              </ul>

              <h2 className="primary-font">3. Cookies</h2>
              <p>
                We may use cookies to improve your experience. Cookies are short
                files on your device for us to remember your preferences. Accept
                or reject cookies through your browser settings is the option you
                have.
              </p>

              <h2 className="primary-font">4. Data Security</h2>
              <p>
                We take cyber security seriously. However, no online service can
                be fully secured. So, we implement ways to secure your information
                from unapproved access.
              </p>

              <h2 className="primary-font">5. Third-Party Links</h2>
              <p>
                Our website may include links to third-party sites. We are not
                liable for their privacy practices. Therefore, we help you to go
                through their privacy policies.
              </p>

              <h2 className="primary-font">6. Children’s Privacy</h2>
              <p>
                Calcuvate is not intended for use by children under the age of 13.
                We do not knowingly collect personal information from children
                under 13. If we become aware of any such data, we will take steps
                to delete it.
              </p>
              <h2 className="primary-font">7. Changes to This Privacy Policy</h2>
              <p>
                We may revise this Privacy Policy occasionally. Updates will be
                posted on this page with changes in an effective date.
              </p>
              <h2 className="primary-font">8. Contact Us</h2>
              <p>
                In case of any concerns about our Privacy Policy, please reach out
                to us at <Link to="">info@calcuvate.com</Link>
              </p>
            </div>
            <p>Your trust and support is valuable to us. Thank you for using Calcuvate!</p>
          </section>
        </div>
      </Layout>
    </>
  );
}
