import React from "react";
import Layout from "../component/Layout";
import Icons from "../Assets/Icons";

export default function BMRCalculator() {
  return (
    <Layout>
      <div className="container">
        <section className="content-cover-box">
          <div className="calculate-percentage">
            {/* Percentage Calculator  */}

            <div className="">
              <h2 className="primary-font mt-0 mb-1">BRM Calculator</h2>
              <div className="calculate-percentage-label">
                Adjust the values and press the calculate button to see the
                results
              </div>
              <div className="calculate-card">
                <div className="bmi-calculate">
                  <div className="mbi-calculate-left">
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Age</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="20" />
                        <div className="bmi-age-count">
                          <p>Ages: 2-120</p>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Gender</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Male</span>
                        </div>
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Female</span>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Hight</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="172" />
                        <div className="">
                          <select>
                            <option>Cm</option>
                            <option>Cm</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Weight </p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="62" />
                        <div className="">
                          <select>
                            <option>Kg</option>
                            <option>Kg</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Activity</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <div className="activity-select">
                          <select>
                            <option></option>
                            <option></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btns">
                      <button className="btn-theme">Calculate</button>
                      <button className="btn-theme-outline">Clear</button>
                    </div>
                  </div>
                  <div className="mbi-calculate-right border-left">
                    <div className="calorie-result-unit">
                      <div className="result-unit-check">
                        <h3>Result Unit:</h3>
                        <div className="d-flex align-items-center gap-10">
                          <div className="gender-select">
                            <input type="radio" />
                            <span>Calories</span>
                          </div>
                          <div className="gender-select">
                            <input type="radio" />
                            <span>Kilojoules</span>
                          </div>
                        </div>
                      </div>
                      <div className="result-unit-check">
                        <h3>BMR estimation formula: </h3>
                        <div className="">
                          <div className="gender-select mb-10">
                            <input type="radio" />
                            <span>Mifflin St Jeor</span>
                          </div>
                          <div className="gender-select mb-10">
                            <input type="radio" />
                            <span>Revised Harries-Benedict</span>
                          </div>
                          <div className="calculate-fat-contin">
                            <div className="gender-select mb-10">
                              <input type="radio" />
                              <span>Katch-McArdie</span>
                            </div>
                            <div className="calculate-fat">
                              <div className="">
                                <p>Body Fat</p>
                              </div>
                              <input
                                type="text"
                                className="ad-percentage"
                                value={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calculate-card">
              <div className="result-box-contin">
                <div className="result-box">Result:</div>
                <div className="">
                  <h2>BRM = 1,560 Calories/day</h2>
                  <p className="mb-0">
                    Daily calorie needs based on activity level
                  </p>
                </div>
                <div className="bmi-label-contin">
                  <div className="bmi-label-list">
                    <div className="bmi-label-box-left">
                      <p>Activity Level</p>
                    </div>
                    <div className="bmi-label-box-right">
                      <p>Calories</p>
                    </div>
                  </div>
                </div>
                <div className="bmi-count-contin">
                  <div className="bmi-count-list">
                    <div className="bmi-count-box-left">
                      <p>Sedentary: little or no exercise</p>
                    </div>
                    <div className="bmi-count-box-right">
                      <p>1,872</p>
                    </div>
                  </div>
                </div>
                <div className="bmi-count-contin">
                  <div className="bmi-count-list">
                    <div className="bmi-count-box-left">
                      <p>Exercise 1-3 times/week</p>
                    </div>
                    <div className="bmi-count-box-right">
                      <p>2,145</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="bmi-count-contin">
                  <div className="bmi-count-list">
                    <div className="bmi-count-box-left">
                      <p>Exercise 4-5 times/week</p>
                    </div>
                    <div className="bmi-count-box-right">
                      <p>2,285</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="bmi-count-contin">
                  <div className="bmi-count-list">
                    <div className="bmi-count-box-left">
                      <p>Daily exercise or intense exercise 3-4 times/week</p>
                    </div>
                    <div className="bmi-count-box-right">
                      <p>2,418 </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="bmi-count-contin">
                  <div className="bmi-count-list">
                    <div className="bmi-count-box-left">
                      <p>Intense exercise 6-7 times/week</p>
                    </div>
                    <div className="bmi-count-box-right">
                      <p>2,691</p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="calories-point-list">
                    <ul>
                      <li>
                        Exercise: 15-30 minutes of elevated heart rate activity.
                      </li>
                      <li>
                        Intense exercise: 45-120 minutes of elevated heart rate
                        activity.
                      </li>
                      <li>
                        Very intense exercise: 2+ hours of elevated heart rate
                        activity.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="share-link-calculator">
                    <div className="">
                      <input
                        type="text"
                        value="https:// .flaticon.com /free-icon/sh are_182895"
                      />
                      <p className="text-left">Copy and share this link</p>
                    </div>
                    <button className="share-link-btn">
                      Share {Icons.ShareBtnIcon}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <p>
              The Basal Metabolic Rate (BMR) represents the energy your body
              needs when resting in a comfortable environment. Your digestion
              should stay inactive. Hence, to figure out how much gas a parked
              car uses while staying idle.  Most of the energy goes to keeping
              vital organs working, such as the heart, brain, and lungs. About
              70% of the calories burned daily come from this basic maintenance
              for many people. Your physical activity accounts for about 20%
              while you use 10% for digesting food. You should measure BMR under
              specific conditions while you’re awake. You should be completely
              relaxed, keeping your nervous system calm to get an accurate
              reading. Usually, it makes up the largest portion of your daily
              calorie needs. Afterward, figure out your total daily calories,
              you should multiply it by a factor between 1.2 and 1.9. It depends
              on how active you are.
            </p>

            <h2 className="primary-font">bMR variables</h2>
            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Muscle Mass:</b> Aerobic exercises, like running or
                  cycling, don't affect basal metabolic rate. However, anaerobic
                  exercises, such as weightlifting, help build muscle, which can
                  raise it. More muscle means your body needs more energy even
                  at rest.
                </li>
                <li>
                  <b>Age:</b> As people get older, their BMR usually decreases.
                  This means they need fewer calories to keep their organs
                  functioning.
                </li>
                <li>
                  <b>Genetics:</b> Your family traits and background can affect
                  your basal metabolic rate.
                </li>
                <li>
                  <b>Weather:</b> Cold temperatures increase basal metabolic
                  rate because your body uses more energy to maintain a stable
                  temperature. Similarly, in hot conditions, the body expends
                  energy to cool itself down. BMR can rise by about 7% for every
                  increase of 1.36 degrees Fahrenheit in body temperature.
                </li>
                <li>
                  <b>Diet:</b> Eating small, frequent meals can lead to higher
                  BMR, while starvation can lower it by up to 30%. The body will
                  cut back on energy use and other functions when calories are
                  low.
                </li>
                <li>
                  <b>Pregnancy:</b> When a woman is pregnant, her BMR increases
                  to support the developing fetus. This is why pregnant women
                  tend to eat more often. Menopause can also change it because
                  of hormonal shifts.
                </li>
                <li>
                  <b>Supplements:</b> Some supplements or medications can raise
                  the basal metabolic rate, often to help with weight loss.
                  Caffeine is a common example.
                </li>
              </ul>
            </div>
            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Exercise:</b> 15-30 minutes of elevated heart rate
                  activity.
                </li>

                <li>
                  <b>Intense exercise:</b> 45-120 minutes of elevated heart rate
                  activity.
                </li>
                <li>
                  <b>Very intense exercise:</b> 2+ hours of elevated heart rate
                  activity.
                </li>
              </ul>
            </div>

            <h2 className="primary-font">How is BMR Calculated?</h2>
            <p>
              You can calculate your BMR by doing a simple math equation.
              Moreover, if you want the most precise measurement, you should
              visit a clinic that offers specialized tests called calorimetry.
            </p>
            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Direct Calorimetry:</b>
                  <br /> This is the most accurate method. It requires you to
                  stay in a special room, called a calorimeter, where you move
                  very little. Researchers mainly use this method.
                </li>

                <li>
                  <b>Indirect Calorimetry:</b> <br /> This method uses a device
                  in the office to measure how much oxygen you use and how much
                  carbon dioxide you breathe out.
                  <p>
                    Since most people can't access these tests, researchers have
                    created easier ways to estimate BMR. One popular method is
                    the Mifflin-St. Jeor equation, which lets you calculate it
                    quickly.
                  </p>
                  <p>
                    You can BMR Calculator, to find your BMR/RMR. Use any
                    formula given below:
                  </p>
                </li>
                <li>
                  For Males: 10 × weight (kg) + 6.25 × height (cm) – 5 × age
                  (years) + 5
                </li>
                <li>
                  For Females: 10 × weight (kg) + 6.25 × height (cm) – 5 × age
                  (years) – 161
                </li>
              </ul>
            </div>
            <h2 className="primary-font">What Does BMR Tell You?</h2>
            <p>
              Your BMR shows how many calories your body burns when you're at
              rest. It gives you a basic idea of your metabolism before you
              factor in daily activities.
            </p>
            <p>
              However, it doesn’t determine your total daily calorie needs
              alone, it's just the beginning of a bigger calculation.
            </p>
            <p>
              To figure out your Total Daily Energy Expenditure (TDEE), you need
              to multiply your BMR by an activity factor:
            </p>
            <p>1.2: Sedentary (little to no exercise)</p>
            <p>1.375: Lightly active (light exercise 1 to 3 days a week)</p>
            <p>
              1.55: Moderately active (moderate exercise 6 to 7 days a week)
            </p>
            <p>1.725: Quite active (hard exercise every day or twice a day)</p>
            <p>
              1.9: Extremely active (quite intense exercise, training, or a
              physically demanding job)
            </p>
            <p>You can calculate your TDEE using this formula:</p>
            <h3>TDEE = BMR x Activity Factor </h3>
            <p>
              This number will give you an estimate of how many calories you
              need each day to keep your current weight.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
}
