import React from 'react';
// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
const SEO = ({
    title = 'Calcuvate: Free Online Calculators - Math, Finance and More',
    description = 'Calcuvate offers Free Online Calculators for math, finance, fitness, and everyday use. Get accurate results quickly and easily for all your calculation needs.',
    image = 'https://www.calcuvate.com/socials/social-icon.jpg',
    url = 'https://www.calcuvate.com/',
    siteName = 'Calcuvate',
    type = 'website'
}) => {
    return (
        <Helmet>
            <title>{title}</title>

            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            {/* Open Graph Meta Tags */}
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="thumbnail" content={image} />
        </Helmet>
    );
};

export default SEO;

