import React from "react";
import { Link } from "react-router-dom";

export default function MostLIkeBlogs() {
  return (
    <div>
      <div className="blog-more-title">
        <p>More like this</p>
      </div>
      <div className="single-blog-more">
        <ul>
          <li>
            <Link
              to="/the-importance-of-online-scientific-calculator"
              className="blog-more-card"
            >
              <h5>Blog:</h5>
              <p> The Importance Of Online Scientific Calculator</p>
            </Link>
          </li>
          <hr />
          <li>
            <Link
              to="/mortgage-calculator-tracking-monthly-payments"
              className="blog-more-card"
            >
              <h5>Blog:</h5>
              <p>Mortgage Calculator: Tracking Monthly Payments</p>
            </Link>
          </li>
          <hr />
          <li>
            <Link
              to="/how-online-math-calculators-simplify-students-life"
              className="blog-more-card"
            >
              <h5>Blog:</h5>
              <p>How Math Calculators Simplify Student Life?</p>
            </Link>
          </li>
          <hr />
          <li>
            <Link
              to="/the-role-of-free-online-calculators-in-health-fitness"
              className="blog-more-card"
            >
              <h5>Blog:</h5>
              <p>The Role Of Calculators In Health & Fitness</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
