import React from "react";
import Layout from "../component/Layout";
import Icons from "../Assets/Icons";

export default function CalorieCalculator() {
  return (
    <Layout>
      <div className="container">
        <section className="content-cover-box">
          <div className="calculate-percentage">
            {/* Percentage Calculator  */}

            <div className="">
              <h2 className="primary-font mt-0 mb-1">Calorie Calculator</h2>
              <div className="calculate-percentage-label">
                Adjust the values and press the calculate button to see the
                results
              </div>
              <div className="calculate-card">
                <div className="bmi-calculate">
                  <div className="mbi-calculate-left">
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Age</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="20" />
                        <div className="">
                          <p>Ages: 2-120</p>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Gender</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Male</span>
                        </div>
                        <div className="gender-select">
                          <input type="radio" />
                          <span>Female</span>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Hight</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="172" />
                        <div className="">
                          <select>
                            <option>Cm</option>
                            <option>Cm</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Weight </p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <input type="text" value="62" />
                        <div className="">
                          <select>
                            <option>Kg</option>
                            <option>Kg</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mbi-calculate-row">
                      <div className="mbi-label">
                        <p>Activity</p>
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <div className="activity-select">
                          <select>
                            <option></option>
                            <option></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btns">
                      <button className="btn-theme">Calculate</button>
                      <button className="btn-theme-outline">Clear</button>
                    </div>
                  </div>
                  <div className="mbi-calculate-right border-left">
                    <div className="calorie-result-unit">
                      <div className="result-unit-check">
                        <h3>Result Unit:</h3>
                        <div className="d-flex align-items-center gap-10">
                          <div className="gender-select">
                            <input type="radio" />
                            <span>Calories</span>
                          </div>
                          <div className="gender-select">
                            <input type="radio" />
                            <span>Kilojoules</span>
                          </div>
                        </div>
                      </div>
                      <div className="result-unit-check">
                        <h3>BMR estimation formula: </h3>
                        <div className="">
                          <div className="gender-select mb-10">
                            <input type="radio" />
                            <span>Mifflin St Jeor</span>
                          </div>
                          <div className="gender-select mb-10">
                            <input type="radio" />
                            <span>Revised Harries-Benedict</span>
                          </div>
                          <div className="calculate-fat-contin">
                            <div className="gender-select mb-10">
                              <input type="radio" />
                              <span>Katch-McArdie</span>
                            </div>
                            <div className="calculate-fat">
                              <div className="">
                                <p>Body Fat</p>
                              </div>
                              <input
                                type="text"
                                className="ad-percentage"
                                value={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calculate-card">
              <div className="result-box-contin">
                <div className="result-box">Result:</div>
                <div className="">
                  <p className="mb-0">
                    The results offer daily calorie estimates to help you
                    determine how many calories to
                  </p>
                  <p className="mb-0 mt-0">
                    consume each day to maintain, lose, or gain weight at your
                    desired rate
                  </p>
                </div>
                <div className="calories-count-contin">
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Maintain Weight</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Mild Weight loss</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Weight Loss</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Extreme weight Loss</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="mb-0">
                    Consult with a doctor if you lose 1 kg or more per week, as
                    it may involve consuming less
                  </p>
                  <p className="mb-0 mt-0">
                    than the recommended 1,500 calories per day
                  </p>
                </div>
                <div className="calories-count-contin">
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Mild Weight Gain </p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Weight Gain</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                  <div className="calories-count-list">
                    <div className="calories-count-box-left">
                      <p>Fast Weight Gain</p>
                    </div>
                    <div className="calories-count-box-right">
                      <h3>2,373</h3>
                      <p>Calories/day</p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="calories-point-list">
                    <ul>
                      <li>
                        Exercise: 15-30 minutes of elevated heart rate activity.
                      </li>
                      <li>
                        Intense exercise: 45-120 minutes of elevated heart rate
                        activity.
                      </li>
                      <li>
                        Very intense exercise: 2+ hours of elevated heart rate
                        activity.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="container">
                  <div className="share-link-calculator">
                    <div className="">
                      <input
                        type="text"
                        value="https:// .flaticon.com /free-icon/sh are_182895"
                      />
                      <p className="text-left">Copy and share this link</p>
                    </div>
                    <button className="share-link-btn">
                      Share {Icons.ShareBtnIcon}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Exercise:</b> 15-30 minutes of elevated heart rate
                  activity.
                </li>
                <li>
                  <b>Intense exercise:</b> 45-120 minutes of elevated heart rate
                  activity.
                </li>
                <li>
                  <b>Very intense exercise:</b> 2+ hours of elevated heart rate
                  activity.
                </li>
              </ul>
            </div>

            <p>
              You can use the Calorie Calculator to identify how many calories
              you need in a day to stay active and healthy. It also considers
              factors like your age, gender, weight, height, and how active you
              are. It uses mathematical formulas to find out how many calories
              your body needs to function at rest. Further, it adds in the
              calories your body burns from being active to give you a total of
              your daily needs. It uses different equations to give you an
              estimated average result. Here, females require 1,600 calories a
              day to maintain their weight whereas males require 2,000 calories
              per day. A calorie Calculator typically uses formulas like the
              Harris-Benedict or Mifflin-St Jeor equation to estimate your Basal
              Metabolic Rate (BMR) and the calories your body needs at rest. It
              then takes your activity level to identify your Total Daily Energy
              Expenditure (TDEE).
            </p>

            <h3>Equations to Calculate Calories</h3>
            <p>Mifflin-St Jeor Equation:</p>
            <p className="mb-0">For men:</p>
            <p className="mt-0 pl-80">BMR = 10W + 6.25H - 5A + 5</p>
            <p className="mb-0">For women: </p>
            <p className="mt-0 pl-80"> BMR = 10W + 6.25H - 5A - 161</p>

            <p>Revised Harris-Benedict Equation:</p>
            <p className="mb-0">For men:</p>
            <p className="mt-0 pl-80">
              BMR = 13.397W + 4.799H - 5.677A + 88.362
            </p>
            <p className="mb-0">For women: </p>
            <p className="mt-0 pl-80">
              BMR = 9.247W + 3.098H - 4.330A + 447.593
            </p>
            <p className="mb-0">Katch-McArdle Formula: </p>
            <p className="mt-0 pl-80">BMR = 370 + 21.6(1 - F)W</p>

            <p className="mb-0">where:</p>
            <p className="mb-0 mt-0 ">W is body weight in kg </p>
            <p className="mb-0 mt-0 ">H is body height in cm </p>
            <p className="mb-0 mt-0 ">A is age </p>
            <p className="mb-0 mt-0 ">F is body fat in percentage</p>

            <h2 className="primary-font">
              Understanding Calculation of Calories
            </h2>
            <p>
              The number from these calculations shows how many calories a
              person can eat in a day to keep their weight stable if they stay
              at rest. This number is then multiplied by an activity factor
              (usually between 1.2 and 1.95). It is based on how active a person
              is, which accounts for their daily activities. To lose weight,
              it's important to know that 1 pound (about 0.45 kg) is roughly
              equal to 3,500 calories. To lose 1 pound in a week, you should eat
              500 fewer calories each day than what you need to maintain your
              weight. Thus, ‘Calcuvate’ is a perfect tool for tracking your
              wellness journey.
            </p>

            <h2 className="primary-font">Minimizing Calorie Intake</h2>

            <div className="container">
              <ul className="bmi-range">
                <li>
                  <b>Eat Protein:</b> Eating protein helps you feel full longer,
                  which can reduce overall caloric intake. Lean meats, beans,
                  and nuts are amazing sources of food options. Protein also
                  supports muscle growth and repair, making it beneficial for
                  maintaining a healthy body while losing weight.
                </li>
                <li>
                  <b>Limit Sugary Drinks:</b> Sugary drinks like soda and energy
                  drinks are high in calories but low in nutrients. Cutting back
                  on these beverages can significantly lower your daily caloric
                  intake. Instead, opt for water or unsweetened drinks, which
                  keep you hydrated without the extra calories.
                </li>
                <li>
                  <b>Drink More Water:</b> Drinking water can help you feel full
                  and reduce hunger, which may lead to eating fewer calories. In
                  addition, more important for overall health and aids in
                  digestion. Drink water throughout the day, before meals, to
                  help control your appetite.
                </li>
                <li>
                  <b>Exercise Daily:</b> Regular body burns reduce calories and
                  maintain a healthy weight. It also boosts your metabolism,
                  which can make it easier to lose weight. You should aim for at
                  least 30 minutes of activity each day. Whether, it's walking,
                  jogging, or any form of physical activity.
                </li>
                <li>
                  <b>Reduce Eating Refined Carbs & Processed Foods:</b> Refined
                  carbs and processed foods often contain added sugars and
                  unhealthy fats. They contain increased amounts of calories and
                  fewer nutrients. Put a focus on whole foods like fruits,
                  vegetables, and whole grains instead. This shift can help you
                  feel fuller and more satisfied while eating fewer calories.
                </li>
              </ul>
            </div>

            <h2 className="primary-font">
              Need for Healthy Diet and Nutrition
            </h2>
            <p>
              Eating a healthy diet throughout your life helps avoid
              malnutrition and various non-communicable diseases (NCDs).
              However, the rise of processed foods, urban living, and changing
              lifestyles have changed what people eat. Individuals are now
              preferring to eat more foods that are high in calories, fats,
              sugars, and salt. They do not take enough fruits, vegetables, and
              fiber from whole grains.  While counting caloric intake can help
              in weight loss. Similarly, it’s important to keep in mind that
              there are many other factors to consider when choosing what and
              how to eat. Instead of targeting calories, it’s best to follow a
              healthy and balanced diet. Therefore, it is crucial to understand
              the need for comprehensive body nutrition.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
}
