import React from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import SEO from "../component/SEO";

export default function ContactUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="Contact Us - Calcuvate"
        description="Reach out to us in case you need any kind of assistance. 'Calcuvate' offers a supportive interface to help you calculate the numeric values."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/contact-us"
      />
      <Layout>
        <div className="container">
          <section className="other-commn-page content-cover-box">
            <div>
              <h1 className="primary-font">Contact Us</h1>
            </div>
            <div className="contact-commn-box">
              <div className="contact-us-view">
                <div>
                  <h4>Feedback</h4>
                  <p>
                    We appreciate your opinions and want to hear from you. Leaving
                    feedback gives us a chance to improve. So, please share your
                    views with us!
                  </p>
                </div>
                <div className="line-middle"></div>
                <div>
                  <h4>Advertising</h4>
                  <p>
                    Want to promote your brand? Get ready to boost your visibility
                    and reach a wider audience. Let us handle your advertising
                    needs.
                  </p>
                </div>
              </div>
              <h2 className="mb-0">info@calcuvate.com</h2>
            </div>
            <div className="contact-commn-box">
              <div className="mb-4">
                <h4>Visit us</h4>
                <p>71-75 Shelton St,</p>
                <p> London WC2H 9JQ,</p>
                <p> United Kingdom</p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
